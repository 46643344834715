import BeiAnFooter from "./phone/component/beiAn/beiAn";
import { BrowserRouter as Router } from "react-router-dom";
import { HomeProvider } from "./context/HomeContext";
import { HomeRouter } from "./routes/HomeRouter";
import "./App.css";

function App() {
  return (
    <div className="App">
      <HomeProvider>
        <Router>
          <HomeRouter />
        </Router>
      </HomeProvider>
    </div>
  );
}

export default App;
